<template>
  <h2>Coupon</h2>
  <div v-if="coupon.id">
    <span>{{ coupon.title }}</span> - <span>{{ coupon.description }}</span><button type="button" @click="coupon = {}; $emit('coupon', coupon)">
      X
    </button>
  </div>
  <div class="error" v-if="discount.error || error">
    {{ discount.error || error }}
  </div>
  <div v-if="discount.discount > 0">
    -{{ discount.discount.toFixed(2) }}€ Rabatt
    <hr class="separator">
  </div>
  <div class="coupon-wrapper">
    <div class="grid-12">
      <div class="col-sp-12 row-sp-12">
        <input style="width: calc(100% - 16px);" type="text" v-model="code" @input="checking = false; error = ''" @keydown.enter="getCoupon">
      </div>
      <div class="col-sp-12 row-sp-12 t-center;" style="margin-top: 12px; text-align: right; ">
        <button type="button" class="button bgcolor-blue color-white" :disabled="checking" :class="{disabled: checking}" @click="getCoupon">
          {{ coupon.id ? 'Ändern' : 'Hinzufügen' }}
        </button>
        <div class="couponButtons">
          <scanner @scan="scanResult" />
        </div>
      </div>
    </div>
    <loader size="small" v-if="checking" />
  </div>
</template>

<script>
import Scanner from "@/components/basic/scanner";
import Loader from "@/components/basic/loader";
import {aSyncData, stringToDate} from "@/modules/main";
import {userStore} from "@/store/userStore";
export default {
  name: "coupon",
  components: {Loader, Scanner},
  setup(){
    const user = userStore()
    return{user}
  },
  props: {
    items: {
      type: Object,
      required: true
    }
  },
  emits: ['coupon'],
  data(){
    return{
      code: this.user.coupons.code?this.user.coupons.code:'',
      checking: false,
      error: '',
      coupon: {}
    }
  },
  computed:{
    discount(){
      let discountItems = []
      let total = 0
      let totalQuantity = 0
      let result = {}
      result.error = ''
      result.discount = 0
      if(!this.coupon.id) return result

      const couponData = this.coupon.data

      if(this.coupon.data.platform === 'offline'){
        result.error = 'Gutschein nicht online gültig'
        return result
      }

      if(stringToDate(this.coupon.validUntil) < Date.now()){
        result.error = 'Gutschein abgelaufen'
        return result
      }

      if(stringToDate(this.coupon.validFrom) > Date.now()){
        result.error = 'Gutschein noch nicht gültig'
        return result
      }

      for(const key in this.items){
        for(let key2 in this.items[key]){
          key2 = parseInt(key2)
          console.log(key, key2)
          if(couponData[key].type === 'all' || (couponData[key].type === 'included' && couponData[key].items.includes(key2)) || (couponData[key].type === 'excluded' && !couponData[key].items.includes(key2))) {
            discountItems.push(this.items[key][key2])
            total += this.items[key][key2].price * this.items[key][key2].quantity
            totalQuantity += this.items[key][key2].quantity
          }
        }
      }

      discountItems.sort((a, b) => {
        return a.price < b.price
      })

      console.log(total)
      if(this.coupon.data.discount.minAmount > total){
        result.error = 'Mindestwert von ' + this.coupon.data.discount.minAmount + '€ nicht erreicht';
        return result
      }

      if(couponData.discount.type === 'percent'){
        result.discount = total * couponData.discount.value / 100
      }else if(couponData.discount.type === 'amount') {
        result.discount = Math.min(total, couponData.discount.value)
      }else{
        let discountCounter = Math.floor(totalQuantity / couponData.discount.x)
        discountItems.forEach(item => {
          if(discountCounter){
            result.discount = item.price * Math.min(item.quantity, discountCounter)
            discountCounter -= Math.min(item.quantity, discountCounter)
          }
        })
      }
      console.log(result)
      result.id = this.coupon.id
      this.$emit('coupon', result)
      return result
    }
  },
  mounted() {
    this.getCoupon()
  },
  methods: {
    getCoupon(){
      if(!this.code.length) return
      this.checking = true
      this.error = ''
      aSyncData('/coupon/check', { code: this.code})
          .then(r => {
            switch (r.status){
              case 250:
                this.coupon = r.data
                break

              case 290:
                this.error = 'Gutschein nicht gefunden'
                break
            }
          })
          .catch(e => {
            this.error = 'Allgemeiner Fehler aufgetreten'
          })
          .finally(() => {
            this.checking = false
          })

      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

        ttq.load('CGCR9LRC77U734TI19L0');
        ttq.page();
      }(window, document, 'ttq');
    },
    scanResult(scan){
      try {
        let result = JSON.parse(scan.data)
        if(result.type === 'coupon') this.code = result.value
        this.$nextTick(() => {
          this.getCoupon()
        })
      }catch (e) {
        alert('QR-Code fehlerhaft')
      }
    }
  }
}
</script>

<style scoped>
  .error{
    color: red;
  }

  .separator{
    opacity: 0.1;
    margin: 16px 0;
  }

  .coupon-wrapper{
    column-gap: 8px;
  }

  .coupon-wrapper input{
    padding: 8px;
    width: 80%;
    max-width: 300px;
    border-radius: 8px;
    border: none;
    background-color: #1E1E1E;
    color: white;
    margin: 8px 0 0;
  }

  .couponButtons button {
    padding: 8px;
    /*width: 80%;*/
    max-width: 300px;
    border-radius: 8px;
    border: none;
    background-color: #1E1E1E;
    color: white;
    margin-bottom: 8px;
  }

  .couponButtons{
    justify-content: center;
    column-gap: 12px;
    margin: 8px 0;
  }


</style>