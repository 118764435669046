import { defineStore } from 'pinia'
import {userStore} from "@/store/userStore";
import axios from "axios";

export const configStore = defineStore('config', {
    state: () => ({
        projectURL: 'https://app4clubs.app/',
        projectURLRaw: 'app4clubs.app',
        eventMediaUploadPath: 'assets/images/events/',
        darkMode: 0,
        volume: 0,
        vibrate: 0,
        chatModal: false,
        sidebar: false,
        now: Date.now(),
        platform: process.env.CORDOVA_PLATFORM ? process.env.CORDOVA_PLATFORM.toLowerCase() : 'web',
        cordova: !!process.env.CORDOVA_PLATFORM,
        onlineStatus: 15*60*1000,
        menuModal: false,
        ad: false,
        pickUpNumber: ''
    }),
    getters: {
        timer() {
            return this.now
        },
        calculateTimeString () {
            return (time) => {
                let remainTime = Math.floor((Date.now() - time) / 1000)
                let timeString = remainTime + 's ago'
                if (remainTime <= 10) timeString = 'Now'
                if (remainTime > 59) {
                    remainTime = Math.floor(remainTime / 60)
                    timeString = remainTime + ' min. ago'
                    if (remainTime > 59) {
                        remainTime = Math.floor(remainTime / 60)
                        timeString = remainTime + ' h ago'
                        if (remainTime > 23) {
                            remainTime = Math.floor(remainTime / 24)
                            timeString = remainTime + ' days ago'
                            if (remainTime === 1)timeString = remainTime + ' day ago'
                            if (remainTime > 6) {
                                const date = new Date(time)
                                timeString = 'on ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                            }
                        }
                    }
                    return timeString
                }}
        },
        getMobileOperatingSystem() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        },
    },
    actions: {
        getAd(path) {
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('page', path)
                const config = configStore()
                axios.post(config.projectURL+'home/getAd',fD)
                    .then((response) => {
                        resolve(response.status)
                        this.ad = response.data
                    })
                    .catch(()=> reject(error))
            })
        },
    }
})